import React from "react";
import { PageLayout, TypographyV2 } from "@components";
import { JoinInstitutionsV2 } from "pages/landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import { VirtualFreezerHero } from "./_components/_VirtualFreezerHero/_VirtualFreezerHero";
import { StaticImage } from "gatsby-plugin-image";
import FeatureCard, {
	FeatureCardLayout,
	FeatureCardProps,
} from "@components/FeatureCard/FeatureCard";
import * as styles from "./virtual-freezers.module.scss";
import { Faqs } from "@components/LimsSoftware/Faqs";
import TitleWithImage from "./_components/_TitleWithImage";
import LabConsumablesCards from "../lab-consumables/_components/LabConsumablesCards";
import { CardWithIconProps } from "@components/CardWithIcon/CardWithIcon";
import Margins from "@components/Margins/Margins";

const cards: FeatureCardProps[] = [
	{
		title: "A fresh take on lab inventory management",
		image: (
			<StaticImage
				loading="eager"
				src={"./_assets/inventory-shelf-rack.png"}
				alt="A fresh take on sample storage"
			/>
		),
		list: [
			"Leave behind the hassle of spreadsheets and embrace an intuitive, user-friendly lab inventory management software.",
			"Personalize your storage, from shelves to cryoboxes, so that every item has its own designated space (even those bulkier ones).",
			"Simplify access with a handy bookmark feature that makes your most commonly-used items even easier to reach.",
		],
		className: styles.cardWrapper,
	},
	{
		title: "User-centric design for reagent integration",

		image: (
			<StaticImage
				loading="eager"
				src={"./_assets/box-view.png"}
				alt="User-centric design for reagent integration"
			/>
		),
		list: [
			"Our user-friendly interface makes adding reagents a breeze. Choose from preset configurations or create your own custom item types.",
			"Keep your data safe and secure with export options and boost your inventory tracking with printable barcodes.",
			"Enjoy a system that’s designed to combine maximum user comfort with advanced features.",
		],
		orientation: "right",
		className: styles.cardWrapper,
	},
	{
		title: "Comprehensive search tools",
		image: (
			<StaticImage
				loading="eager"
				src={"./_assets/freezers-advanced-search.png"}
				alt="Comprehensive search tools"
			/>
		),
		list: [
			"Instantly locate every sample and reagent in your inventory, anytime, thanks to our powerful search tool.",
			"Our centralized lab inventory management software provides a single and accurate source of truth for all items.",
			"Boost your efficiency and find items even faster with our intelligent search filters.",
		],
		className: styles.cardWrapper,
	},
];
const faqs = [
	{
		question:
			"What makes Genemod’s lab inventory management software the right choice for scientists?",
		children: [
			`Genemod’s lab inventory management software is customizable, allowing researchers to create a digital space that mirrors their real-life freezers. It also helps track changes made to samples and manage them more efficiently. Compared to traditional practices like spreadsheets or pen and paper, Genemod’s lab inventory management software is safer and easier to use for managing important samples and data. This is particularly crucial for life science researchers, as losing critical data can delay the entire R&D process by several years.`,
		],
		id: "1",
	},
	{
		question: "Can I import previous sample data into Genemod?",
		children: [
			<TypographyV2
				variant="BODY_TEXT_MEDIUM"
				weight="REGULAR"
				tabletVariant="BODY_TEXT_MEDIUM"
				mobileVariant="BODY_TEXT_MEDIUM"
				color="text-helper"
			>
				Yes, you can import your previous sample data into Genemod using
				CSV and Excel files. If you need to import data from another
				software platform, please{" "}
				<a className={styles.link} href="/contact-us">
					contact Genemod.
				</a>
			</TypographyV2>,
		],
		id: "2",
	},
	{
		question: "Can I export data from Genemod?",
		children: [
			`Yes, you can export data from Genemod's freezer to CSV files.`,
		],
		id: "3",
	},
	{
		question:
			"Are there premade item types, and can I create custom item types?",
		children: [
			`Yes, the premade item types in Genemod's virtual freezer include Cell Line, Enzyme, Plasmid, Chemical Probe, Chemical, Antibody, Strain, and Primer. You can also create custom item types with custom fields.`,
		],
		id: "4",
	},
	{
		question: "How can I track changes made to samples?",
		children: [
			`Genemod LIMS supports an audit trail for all provided features. You can track which sample item was updated, by whom, and when.`,
		],
		id: "5",
	},
	{
		question: "Is there a search feature on Genemod?",
		children: [
			`Yes, Genemod has an advanced search tool that allows you to find items by keyword. You can search for items not only by their names but also by information you’ve added to them, such as notes, vendors, references, catalog numbers, lot numbers, and more. Additionally, you can filter the search results by item type, location, date, and creator.`,
		],
		id: "6",
	},
	{
		question: "How many virtual freezers can we create?",
		children: [
			<TypographyV2
				variant="BODY_TEXT_MEDIUM"
				weight="REGULAR"
				tabletVariant="BODY_TEXT_MEDIUM"
				mobileVariant="BODY_TEXT_MEDIUM"
				color="text-helper"
			>
				You can create up to 3 virtual freezers under Team plan, and
				additional freezers under Business and Enterprise plan. Please
				look at the{" "}
				<a className={styles.link} href="/pricing">
					pricing
				</a>{" "}
				for more information.
			</TypographyV2>,
		],
		id: "7",
	},
	{
		question:
			"Are there limits to the number of boxes and items per virtual freezer?",
		children: [
			`In Genemod's freezer, you can create up to 120 boxes per rack and 225 items per box. You can customize racks, categories, boxes, and items to match your real-life freezers.`,
		],

		id: "8",
	},
];
const labConsumablesCards: CardWithIconProps[] = [
	{
		icon: (
			<svg
				width={32}
				height={33}
				viewBox="0 0 32 33"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect
					y="0.541992"
					width={32}
					height={32}
					rx={8}
					fill="#F5F7FF"
				/>
				<g clipPath="url(#clip0_270_6330)">
					<path
						d="M23 7.54199H13C11.8954 7.54199 11 8.43742 11 9.54199V19.542C11 20.6466 11.8954 21.542 13 21.542H23C24.1046 21.542 25 20.6466 25 19.542V9.54199C25 8.43742 24.1046 7.54199 23 7.54199Z"
						stroke="#2C52F7"
						strokeWidth={2}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M21 21.542V23.542C21 24.0724 20.7893 24.5811 20.4142 24.9562C20.0391 25.3313 19.5304 25.542 19 25.542H9C8.46957 25.542 7.96086 25.3313 7.58579 24.9562C7.21071 24.5811 7 24.0724 7 23.542V13.542C7 13.0116 7.21071 12.5029 7.58579 12.1278C7.96086 11.7527 8.46957 11.542 9 11.542H11"
						stroke="#2C52F7"
						strokeWidth={2}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
				<defs>
					<clipPath id="clip0_270_6330">
						<rect
							width={24}
							height={24}
							fill="white"
							transform="translate(4 4.54199)"
						/>
					</clipPath>
				</defs>
			</svg>
		),
		title: "Stock tracking for biopharma research",
		description:
			"Optimize stock management with dynamic tracking tools for samples and consumables.",
		to: "DASHBOARD",
		Cta: "Stock tracker",
	},
	{
		icon: (
			<svg
				width={32}
				height={33}
				viewBox="0 0 32 33"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect
					y="0.541992"
					width={32}
					height={32}
					rx={8}
					fill="#F5F7FF"
				/>
				<g clipPath="url(#clip0_270_311)">
					<path
						d="M16 14.542C16 12.9507 15.3679 11.4246 14.2426 10.2994C13.1174 9.17413 11.5913 8.54199 10 8.54199H7V10.542C7 12.1333 7.63214 13.6594 8.75736 14.7846C9.88258 15.9099 11.4087 16.542 13 16.542H16"
						stroke="#2C52F7"
						strokeWidth={2}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M16 18.542C16 16.9507 16.6321 15.4246 17.7574 14.2994C18.8826 13.1741 20.4087 12.542 22 12.542H25V13.542C25 15.1333 24.3679 16.6594 23.2426 17.7846C22.1174 18.9099 20.5913 19.542 19 19.542H16"
						stroke="#2C52F7"
						strokeWidth={2}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M16 24.542V14.542"
						stroke="#2C52F7"
						strokeWidth={2}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
				<defs>
					<clipPath id="clip0_270_311">
						<rect
							width={24}
							height={24}
							fill="white"
							transform="translate(4 4.54199)"
						/>
					</clipPath>
				</defs>
			</svg>
		),
		title: "Finally, an ELN built for scientists",
		description:
			"It’s never been easier to cycle across all your experiments, protocols, and attachments in one place.",
		to: "NOTEBOOK",
		Cta: "Explore ELN",
	},
	{
		icon: (
			<svg
				width={32}
				height={33}
				viewBox="0 0 32 33"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect
					y="0.541992"
					width={32}
					height={32}
					rx={8}
					fill="#F5F7FF"
				/>
				<path
					d="M16 6.54199C16.5917 6.54199 17.1713 6.59338 17.7348 6.69193M16 6.54199C10.4772 6.54199 6 11.0191 6 16.542C6 22.0648 10.4772 26.542 16 26.542M16 6.54199V26.542M21.738 8.35099C22.6922 9.02069 23.5241 9.85289 24.1934 10.8074M25.8501 14.8076C25.9486 15.3709 26 15.9505 26 16.542C26 17.1335 25.9486 17.713 25.8501 18.2764M24.1892 22.2826C23.5203 23.2349 22.6896 24.0653 21.7369 24.7337M17.7328 26.3924C17.17 26.4907 16.591 26.542 16 26.542"
					stroke="#2C52F7"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		),
		title: "Transparent and scalable pricing",
		description:
			"Finally, simple and predictable pricing that scales with your team.",
		to: "PRICING",
		Cta: "View pricing",
	},
];

export default function Inventory() {
	return (
		<PageLayout
			seoOptions={{
				type: "PREDEFINED",
				pageName: "virtual-freezers",
			}}
		>
			<VirtualFreezerHero />
			<Margins className={styles.margins}>
				<FeatureCardLayout className={styles.layout}>
					{cards.map((card, index) => {
						return <FeatureCard font sectionAlignment {...card} />;
					})}
				</FeatureCardLayout>
				<TitleWithImage />
				<div className={styles.integrated_bg}>
					<JoinInstitutionsV2
						outerContainerClassName={styles.joinInstitutions}
					/>
				</div>
				<LabConsumablesCards
					btnVariant="light"
					cards={labConsumablesCards}
				/>
			</Margins>
			<Faqs title="Frequently asked questions" faqs={faqs} />
		</PageLayout>
	);
}
